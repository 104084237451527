import React from 'react';

/**
 * EmptyTenantIcon component
 *
 * @return {*}
 */
const EmptyTenantIcon: React.FC = () => {
  return (
    <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72.5599 86.976C69.0399 88.016 64.8799 88.496 59.9999 88.496H35.9999C31.1199 88.496 26.9599 88.016 23.4399 86.976C24.3199 76.576 34.9999 68.376 47.9999 68.376C60.9999 68.376 71.6799 76.576 72.5599 86.976Z"
        stroke="#ADB9E1"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88 36.5V60.5C88 75.62 83.44 83.9 72.56 86.98C69.04 88.02 64.88 88.5 60 88.5H36C31.12 88.5 26.96 88.02 23.44 86.98C12.56 83.9 8 75.62 8 60.5V36.5C8 16.5 16 8.5 36 8.5H60C80 8.5 88 16.5 88 36.5Z"
        stroke="#1C274C"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.328 42.82C62.328 50.74 55.928 57.18 48.008 57.18C40.088 57.18 33.688 50.74 33.688 42.82C33.688 34.9 40.088 28.5 48.008 28.5C55.928 28.5 62.328 34.9 62.328 42.82Z"
        stroke="#ADB9E1"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyTenantIcon;
