import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { DimensionValue, ScrollView, StyleSheet, View } from 'react-native';

export interface ILayout {
  title: string;
  scrollViewProps?: { [key: string]: any };
  children: React.ReactNode;
}

/**
 * Layout component
 *
 * @param {*} { title, scrollViewProps, children }
 * @return {*}
 */
const Layout: React.FC<ILayout> = ({ title, scrollViewProps, children }) => {
  return (
    <View style={styles.appViewStyle}>
      <View style={{ width: '100%' }}>
        <Header title={title} />
      </View>
      <ScrollView
        contentContainerStyle={scrollViewProps ? scrollViewProps : styles.defaultScrollViewProps}>
        {children}
      </ScrollView>
      <View style={{ width: '100%' }}>
        <Footer />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  appViewStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh' as DimensionValue,
    width: '100vw' as DimensionValue,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    color: '#000000'
  },
  defaultScrollViewProps: {
    height: '100%',
    width: '100vw' as DimensionValue,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default Layout;
