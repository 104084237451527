import { PropsWithChildren } from 'react';
import { StyleSheet, Text, View } from 'react-native';

/**
 * Footer component
 *
 * @return {*}
 */
const Footer: React.FC<PropsWithChildren> = () => {
  const currentYear = new Date().getFullYear();
  return (
    <View style={styles.footer}>
      <Text style={styles.footerTextStyle}>
        &copy; {currentYear} Quartech. All rights reserved.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000',
    bottom: 0,
    paddingVertical: 12,
    paddingHorizontal: 24
  },
  footerTextStyle: {
    display: 'flex',
    flex: 1,
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 400
  }
});

export default Footer;
