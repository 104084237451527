import { FormikProps } from 'formik';
import React from 'react';
import { TextInput, Text, StyleSheet } from 'react-native';
import { INewUser } from '../../types/user';

interface IRegisterUserFormProps {
  formikProps: FormikProps<INewUser>;
}

/**
 * RegisterUserForm component
 *
 * @param {*} { formikProps }
 * @return {*}
 */
const RegisterUserForm: React.FC<IRegisterUserFormProps> = ({ formikProps }) => {
  return (
    <>
      <TextInput
        style={styles.input}
        placeholder="Username"
        onChangeText={formikProps.handleChange('name')}
        onBlur={formikProps.handleBlur('name')}
        value={formikProps.values.name}
      />
      {formikProps.errors.name && formikProps.touched.name && (
        <Text style={styles.errorText}>{formikProps.errors.name}</Text>
      )}

      <TextInput
        style={styles.input}
        placeholder="Email Address"
        onChangeText={formikProps.handleChange('email')}
        onBlur={formikProps.handleBlur('email')}
        value={formikProps.values.email}
      />
      {formikProps.errors.email && formikProps.touched.email && (
        <Text style={styles.errorText}>{formikProps.errors.email}</Text>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#f2f2f2',
    borderRadius: 10,
    width: '100%'
  },
  label: {
    marginBottom: 5,
    fontSize: 16,
    fontWeight: '600'
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
    color: '#000',
    backgroundColor: '#fff'
  },
  errorText: {
    color: 'red',
    fontSize: 12,
    marginBottom: 10
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cancelButton: {
    padding: 10,
    backgroundColor: '#ccc',
    borderRadius: 5,
    flex: 1,
    marginRight: 10,
    alignItems: 'center'
  },
  inviteButton: {
    padding: 10,
    backgroundColor: '#003366',
    borderRadius: 5,
    flex: 1,
    alignItems: 'center'
  },
  buttonText: {
    color: '#fff',
    fontWeight: '600'
  }
});

export default RegisterUserForm;
