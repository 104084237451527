import React, { ReactNode, useState } from 'react';
import Icon from '@mdi/react';
import { View, StyleSheet, Pressable, Text, Animated as RNAnimated } from 'react-native';
import { mdiClose } from '@mdi/js';
import { ToastContext } from '.';

type ToastProviderProps = { children: ReactNode };

const styles = StyleSheet.create({
  toastsContainer: {
    position: 'absolute',
    bottom: 90,
    left: '50%',
    transform: [{ translateX: '-50%' }],
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center'
  },
  toastsStyle: {
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: 10,
    width: 320,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  toastSuccess: {
    backgroundColor: '#22c55e'
  },
  toastFail: {
    backgroundColor: '#dc2626'
  },
  toastGeneric: {
    backgroundColor: '#1C274C'
  },
  toastText: {
    color: '#ffffff'
  },
  toastDismiss: {
    cursor: 'pointer',
    marginLeft: 'auto',
    width: 20,
    color: '#ffffff'
  }
});

const Toast: React.FC<{
  message: ReactNode;
  success?: boolean;
  onClose: () => void;
  timeout: number;
}> = ({ message, success, onClose, timeout }) => {
  const opacity = React.useRef(new RNAnimated.Value(0)).current;
  const translateY = React.useRef(new RNAnimated.Value(10)).current;

  React.useEffect(() => {
    RNAnimated.parallel([
      RNAnimated.timing(opacity, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false
      }),
      RNAnimated.timing(translateY, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
      })
    ]).start();

    const timer = setTimeout(() => {
      handleClose();
    }, timeout - 300);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  const handleClose = () => {
    RNAnimated.parallel([
      RNAnimated.timing(opacity, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
      }),
      RNAnimated.timing(translateY, {
        toValue: 10,
        duration: 300,
        useNativeDriver: false
      })
    ]).start(() => {
      onClose();
    });
  };

  const style =
    success === undefined ? styles.toastGeneric : success ? styles.toastSuccess : styles.toastFail;
  return (
    <RNAnimated.View style={[styles.toastsStyle, style, { opacity, transform: [{ translateY }] }]}>
      <Text style={styles.toastText}>{message}</Text>
      <Pressable onPress={handleClose}>
        <Icon path={mdiClose} style={styles.toastDismiss} />
      </Pressable>
    </RNAnimated.View>
  );
};

/**
 * ToastProvider
 *
 * @param {*} { children }
 * @return {*}
 */
const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<
    { id: number; message: ReactNode; success?: boolean; timeout: number }[]
  >([]);

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const showToast = (message: ReactNode, timeout: number, success?: boolean) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, success, timeout }]);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <View style={styles.toastsContainer}>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            success={toast.success}
            onClose={() => removeToast(toast.id)}
            timeout={toast.timeout}
          />
        ))}
      </View>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
