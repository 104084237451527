import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { useTenantContext, useToastContext } from '../../../contexts';
import DeleteConfirmModal from '../../../modal/DeleteConfirmModal';
import Layout from '../../../components/layout/Layout';
import GenericButton from '../../../components/molecules/buttons/GenericButton';
import CredentialList from './credentials/CredentialList';
import GenericBack from '../../../components/molecules/buttons/GenericBack';
import { IUser } from '../../../types/user';
import SendUserCredModal from '../../../modal/SendUserCredModal';
import { useIssuerService } from '../../../services';
import ToastProvider from '../../../contexts/ToastContext';

interface IWalletUserDetailsProps {
  user: IUser;
  setVisible: (value: boolean) => void;
}

/**
 * WalletUserDetails component
 *
 * @param {*} { user, setVisible, visible }
 * @return {*}
 */
const WalletUserDetails: React.FC<IWalletUserDetailsProps> = ({ user, setVisible }) => {
  const tenantContext = useTenantContext();
  const toast = useToastContext();
  const issuerService = useIssuerService();

  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [sendUserCredModalOpen, setSendUserCredModalOpen] = useState(false);

  useEffect(() => {
    const getCredentials = async () => {
      if (user && user.connection_id) {
        await tenantContext.fetchCredentials(user.connection_id);
      }
    };
    if (!tenantContext.credentials) {
      getCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeleteUserModal = () => {
    setDeleteUserModalVisible(true);
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModalVisible(false);
  };

  const closeSendUserCredModal = () => {
    setSendUserCredModalOpen(false);
  };

  const deleteUser = async () => {
    try {
      // Delete user with wallet
      const response = await issuerService.deleteUser(user.email);
      if (response) {
        await tenantContext.fetchUsers();
        toast.showToast('User deleted successfully', 3000);
        setVisible(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ToastProvider>
      <Layout title="Issuer Application">
        <DeleteConfirmModal
          isVisible={deleteUserModalVisible}
          onClose={closeDeleteUserModal}
          onConfirm={deleteUser}
        />
        <SendUserCredModal
          isVisible={sendUserCredModalOpen}
          onClose={closeSendUserCredModal}
          user={user}
        />
        <View style={styles.Container}>
          <GenericBack onPress={() => setVisible(false)} buttonStyle={styles.backButton} />
          <View style={styles.inviteContainer}>
            <View>
              <Text style={styles.nameHeader}>{user?.tenant_name}</Text>
              <Text style={styles.secondaryText}>{user?.email}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <GenericButton
                title={'Issue Credential'}
                onPress={() => setSendUserCredModalOpen(true)}
                buttonStyle={styles.button}
                textStyle={styles.buttonText}
                isPrimary={true}
              />
              <GenericButton
                title={'Delete Wallet'}
                onPress={openDeleteUserModal}
                buttonStyle={styles.button}
                textStyle={styles.buttonText}
                isPrimary={true}
              />
            </View>
          </View>
          <View>
            <Text style={styles.secondaryText}>{`Tenant ID: ${user.tenant_id}`}</Text>
            <Text style={styles.secondaryText}>{`Wallet ID: ${user?.wallet_id}`}</Text>
          </View>
          <View>
            <View>
              <Text
                style={
                  styles.credentialText
                }>{`Credentials (${tenantContext.credentials?.length || 0})`}</Text>
            </View>
            <CredentialList
              user={user}
              credentials={tenantContext.credentials || []}
              setSendUserCredModalOpen={setSendUserCredModalOpen}
            />
          </View>
        </View>
      </Layout>
    </ToastProvider>
  );
};

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    width: '100%'
  },
  emptyCredentialContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  button: {
    width: 141,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  deleteReservationButton: {
    width: 156,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  inviteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  nameHeader: {
    fontSize: 32,
    fontWeight: 700,
    color: '#000000'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  credentialText: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginTop: 21
  },
  noCredentialHeaderText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 2,
    marginBottom: 16
  }
});

export default WalletUserDetails;
