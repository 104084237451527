import { AuthContextProps } from 'react-oidc-context';
import { UserInvitation } from '../types/invitation';
import BaseService from './base';

/**
 * UserService class
 *
 * @export
 * @class UserService
 */
export default class UserService extends BaseService {
  constructor(auth: AuthContextProps) {
    super(auth);
  }

  /**
   * Fetch invitation
   *
   * @param {string} reservation_id
   * @return {*}  {Promise<UserInvitation>}
   * @memberof UserService
   */
  async fetchInvitation(reservation_id: string): Promise<UserInvitation> {
    const body = await this.get<UserInvitation>(`/issuer/user/${reservation_id}`);

    return new UserInvitation(body);
  }

  /**
   * Fetch user invitation data
   *
   * @param {string} reservationId
   * @return {*}  {(Promise<UserInvitation | undefined>)}
   * @memberof UserService
   */
  async fetchInvitationData(reservationId: string): Promise<UserInvitation | undefined> {
    const invitationsResponse = await this.fetchInvitation(reservationId);
    return invitationsResponse || undefined;
  }

  /**
   * Create wallet
   *
   * @return {*}  {Promise<boolean>}
   * @memberof UserService
   */
  async createWallet(): Promise<boolean> {
    const body = await this.get<boolean>('/issuer/user/onboard');

    return body === true;
  }
}
