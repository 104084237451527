import React from 'react';
import { useTenantContext, useToastContext } from '../contexts';
import BlankModal from './BlankModal';
import { IInvitation } from '../types/invitation';
import { useIssuerService } from '../services';
import UserInvitationDetails from '../components/modals/UserInvitationDetails';

export interface SendUserInviteModalProps {
  isVisible: boolean;
  onClose: () => void;
  invitation: IInvitation;
}

/**
 * SendUserInviteModal component
 *
 * @param {*} {
 *   isVisible,
 *   onClose,
 *   invitation
 * }
 * @return {*}
 */
const SendUserInviteModal: React.FC<SendUserInviteModalProps> = ({
  isVisible,
  onClose,
  invitation
}) => {
  const issuerService = useIssuerService();
  const tenantContext = useTenantContext();
  const toast = useToastContext();

  const sendEmail = async (data: IInvitation) => {
    await issuerService.sendEmail(data.email);
    await tenantContext.fetchInvitations();
    console.log('Invitation sent successfully');
    toast.showToast('Invitation sent successfully', 3000, true);
    onClose();
  };

  if (!invitation) return null;

  return (
    <BlankModal
      title="Resend Invitation"
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={() => sendEmail(invitation)}>
      <UserInvitationDetails invitation={invitation} />
    </BlankModal>
  );
};

export default SendUserInviteModal;
