import React, { useCallback, useEffect, useState } from 'react';
import { useIssuerService } from '../services';
import { CredentialContext, useTenantContext } from '.';
import { IUser } from '../types/user';

const CredentialContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const tenantContext = useTenantContext();

  const [schema, setSchema] = useState<any>(null);
  const [attributes, setAttributes] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const issuerService = useIssuerService();

  const sendCredential = useCallback(
    async (data: IUser) => {
      setLoading(true);
      if (schema.ledger_id) {
        await issuerService.sendCredential(data);
      } else {
        const credentialInformation = {
          credentialName: name,
          credentialSubject: attributes.reduce(
            (acc, attr) => {
              acc[attr] =
                {
                  first_name: data.tenant_name.split(' ')[0],
                  last_name: data.tenant_name.split(' ')[1],
                  firstName: data.tenant_name.split(' ')[0],
                  lastName: data.tenant_name.split(' ')[1],
                  location: 'Victoria',
                  serverRoomAccess: 'false',
                  expirationDate: '2025-12-31',
                  email: data.email,
                  photo: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
                  wallet_id: data.wallet_id || 'N/A'
                }[attr] || 'N/A';
              return acc;
            },
            {} as Record<string, string | boolean>
          )
        };
        await issuerService.sendW3CCredential(data, credentialInformation);
      }
      if (data.connection_id) {
        await tenantContext.refreshCredentials(data.connection_id);
      }
      setLoading(false);
    },
    [issuerService, schema, name, attributes, tenantContext]
  );

  useEffect(() => {
    if (schema) {
      setAttributes(schema.schema.attrNames || []);
      setName(schema.schema.name || '');
    } else {
      setAttributes([]);
    }
  }, [schema]);

  return (
    <CredentialContext.Provider
      value={{
        schema,
        attributes,
        name,
        loading,
        setSchema,
        sendCredential
      }}>
      {children}
    </CredentialContext.Provider>
  );
};

export default CredentialContextProvider;
