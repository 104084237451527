import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IssuerAuthGuard from '../guards/IssuerAuthGuard';
import TenantPage from '../pages/TenantPage';
import AuthGuard from '../guards/AuthGuard';
import FallbackPage from '../pages/FallbackPage';
import OnboardPage from '../pages/OnboardPage';
import HomePage from '../pages/HomePage';
import TenantContextProvider from '../contexts/TenantContext';
import AdminAuthGuard from '../guards/AdminAuthGuard';
import AdminPage from '../pages/AdminPage';
import CredentialContextProvider from '../contexts/CredentialContext';

/**
 * AppRouter component
 *
 * @return {*}  {JSX.Element}
 */
const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/admin"
          element={
            <AdminAuthGuard fallback={<HomePage />}>
              <TenantContextProvider>
                <AdminPage />
              </TenantContextProvider>
            </AdminAuthGuard>
          }
        />

        <Route
          path="/"
          element={
            <IssuerAuthGuard fallback={<HomePage />}>
              <TenantContextProvider>
                <CredentialContextProvider>
                  <TenantPage />
                </CredentialContextProvider>
              </TenantContextProvider>
            </IssuerAuthGuard>
          }
        />
        <Route
          path={'/user/invite/:id'}
          element={
            <AuthGuard fallback={<FallbackPage />}>
              <OnboardPage />
            </AuthGuard>
          }
        />
        <Route path="*" element={<FallbackPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
