import React, { useState, ReactNode, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { WebSocketContext } from '.';

interface WebSocketProviderProps {
  children: ReactNode;
  walletId: string;
}

/**
 * WebSocketProvider component
 *
 * @param {*} { children, walletId }
 * @return {*}
 */
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children, walletId }) => {
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const [socketUrl] = useState(`${protocol}://${window.location.host}/ws`);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const connectionStatus =
    {
      0: 'Connecting',
      1: 'Open',
      2: 'Closing',
      3: 'Closed'
    }[readyState as 0 | 1 | 2 | 3] || 'Unknown';

  useEffect(() => {
    try {
      const message = {
        walletId: walletId,
        message: 'Connected!!'
      };
      sendMessage(JSON.stringify(message));
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState, sendMessage]);

  return (
    <WebSocketContext.Provider value={{ sendMessage, lastMessage, connectionStatus }}>
      {children}
    </WebSocketContext.Provider>
  );
};
