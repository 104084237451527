import React from 'react';
import { FlatList } from 'react-native';
import { useTenantContext } from '../../contexts';
import UserItem from './UserItem';
import EmptyTenant from './components/EmptyTenant';
import { IUser } from '../../types/user';
import { IInvitation } from '../../types/invitation';
import UserSkeleton from '../../components/skeletons/UserSkeleton';

/**
 * UsersTable component
 *
 * @return {*}
 */
const UsersTable: React.FC = () => {
  const tenantContext = useTenantContext();

  const renderItem = ({ item, index }: { item: IUser & IInvitation; index: number }) => (
    <UserItem user={item} key={index} />
  );

  const ListFooterComponent = () => (tenantContext.isRefreshing ? <UserSkeleton /> : null);

  return (
    <>
      {tenantContext.isLoading ? (
        <>
          <UserSkeleton />
          <UserSkeleton />
          <UserSkeleton />
        </>
      ) : (
        <>
          {tenantContext.users?.length === 0 && tenantContext.invitations?.length === 0 ? (
            <EmptyTenant />
          ) : (
            <FlatList
              data={[...(tenantContext.users || []), ...(tenantContext.invitations || [])] as any}
              renderItem={renderItem}
              keyExtractor={(item) => item.email as string}
              ListFooterComponent={ListFooterComponent}
              ListFooterComponentStyle={{ marginBottom: 20 }}
            />
          )}
        </>
      )}
    </>
  );
};

export default UsersTable;
