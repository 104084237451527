import React from 'react';
import BlankModal from './BlankModal';
import IssueCredDetails from '../components/modals/IssueCredDetails';
import { IUser } from '../types/user';
import { Picker } from '@react-native-picker/picker';
import { useCredentialContext, useTenantContext, useToastContext } from '../contexts';

export interface SendUserCredModalProps {
  isVisible: boolean;
  onClose: () => void;
  user: IUser;
}

const SendUserCredModal: React.FC<SendUserCredModalProps> = ({ isVisible, onClose, user }) => {
  const credentialContext = useCredentialContext();
  const tenantContext = useTenantContext();
  const toast = useToastContext();

  const sendCredential = async (data: IUser) => {
    credentialContext.sendCredential(data);
    credentialContext.setSchema(null);
    toast.showToast('Credential sent successfully', 3000);
    onClose();
  };

  const onValueChange = (itemValue: any) => {
    const schema =
      tenantContext.schemas?.find((schema) => schema.schema.name === itemValue) || null;
    credentialContext.setSchema(schema);
  };

  return (
    <BlankModal
      title="Issue credential"
      isVisible={isVisible}
      onClose={() => {
        onClose();
        credentialContext.setSchema(null);
      }}
      onConfirm={() => sendCredential(user)}
      acceptText="Issue credential"
      isLoading={credentialContext.loading}
      isDisabled={credentialContext.schema === null}>
      <>
        <Picker
          selectedValue={credentialContext.schema?.schema.name || undefined}
          onValueChange={onValueChange}>
          <Picker.Item label="Select a Credential Schema" value={undefined} />
          {tenantContext.schemas?.map((schema, index) => (
            <Picker.Item key={index} label={schema.schema.name} value={schema.schema.name} />
          ))}
        </Picker>
        {credentialContext.attributes.length > 0 && (
          <IssueCredDetails
            user={user}
            attributes={credentialContext.attributes}
            name={credentialContext.name}
          />
        )}
      </>
    </BlankModal>
  );
};

export default SendUserCredModal;
