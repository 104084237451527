import React from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { useEffect, useRef } from 'react';

/**
 * UserSkeleton component - Loading placeholder for WalletUserItem and InvitationUserItem
 *
 * @return {*}
 */
const UserSkeleton: React.FC = () => {
  const fadeAnim = useRef(new Animated.Value(0.3)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false
        }),
        Animated.timing(fadeAnim, {
          toValue: 0.3,
          duration: 1000,
          useNativeDriver: false
        })
      ])
    ).start();
  }, [fadeAnim]);

  return (
    <View style={styles.card}>
      <View>
        <Animated.View style={[styles.skeletonName, { opacity: fadeAnim }]} />
        <Animated.View style={[styles.skeletonEmail, { opacity: fadeAnim }]} />
      </View>
      <Animated.View style={[styles.skeletonButton, { opacity: fadeAnim }]} />
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    borderTopWidth: 1,
    borderTopColor: '#000000',
    margin: 8,
    padding: 4,
    paddingTop: 20,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  skeletonName: {
    width: 150,
    height: 20,
    backgroundColor: '#E1E1E1',
    borderRadius: 4,
    marginBottom: 8
  },
  skeletonEmail: {
    width: 200,
    height: 20,
    backgroundColor: '#E1E1E1',
    borderRadius: 4
  },
  skeletonButton: {
    width: 150,
    height: 41,
    backgroundColor: '#E1E1E1',
    borderRadius: 6
  }
});

export default UserSkeleton;
