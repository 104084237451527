import React from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import GenericButton from './GenericButton';

export interface GenericCancelProps {
  onPress: () => void;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
  isDisabled?: boolean;
}

/**
 * GenericCancel component
 *
 * @param {*} {
 *   onPress,
 *   buttonStyle,
 *   textStyle,
 *   isDisabled
 * }
 * @return {*}
 */
const GenericCancel: React.FC<GenericCancelProps> = ({
  onPress,
  buttonStyle,
  textStyle,
  isDisabled
}) => {
  return (
    <GenericButton
      title="Cancel"
      onPress={onPress}
      isPrimary={false}
      buttonStyle={buttonStyle}
      textStyle={textStyle}
      isDisabled={isDisabled}
    />
  );
};

export default GenericCancel;
