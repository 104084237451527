import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useConfigContext } from '../contexts';
import GenericButton from '../components/molecules/buttons/GenericButton';

/**
 * FallbackPage component
 *
 * @return {*}
 */
const FallbackPage: React.FC = () => {
  const configContext = useConfigContext();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Access Denied</Text>
      <Text style={styles.message}>You are not authorized to view this page.</Text>
      <GenericButton
        title="Looking for Wallet? Click Here"
        onPress={() => window.open(configContext.WALLET_HOST, '_blank')}
        isPrimary
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: 'black'
  },
  message: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 32,
    color: 'black'
  }
});

export default FallbackPage;
