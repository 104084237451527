import React from 'react';
import { StyleSheet, ViewStyle, Animated } from 'react-native';

interface SkeletonProps {
  skeletonStyle?: ViewStyle | ViewStyle[];
}

/**
 * Skeleton component
 * Provides a loading animation effect
 *
 * @param {SkeletonProps} { style }
 * @return {React.FC}
 */
const Skeleton: React.FC<SkeletonProps> = ({ skeletonStyle }) => {
  const animatedValue = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedValue, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false
        }),
        Animated.timing(animatedValue, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: false
        })
      ])
    ).start();
  }, [animatedValue]);

  const opacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0.3, 0.7]
  });

  return (
    <Animated.View
      style={[
        styles.skeleton,
        skeletonStyle,
        {
          opacity
        }
      ]}
    />
  );
};

const styles = StyleSheet.create({
  skeleton: {
    backgroundColor: '#E1E9EE'
  }
});

export default Skeleton;
