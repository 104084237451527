import React, { createContext, ReactNode, useContext } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import Tenant from '../types/tenant';
import { IInvitation } from '../types/invitation';
import { IUser } from '../types/user';

//================================================
// Auth State Context
//================================================

export interface IAuthState {
  /**
   * The logged in user's Keycloak information.
   *
   * @type {AuthContextProps}
   * @memberof IAuthState
   */
  auth: AuthContextProps;
}

export const AuthStateContext = React.createContext<IAuthState | undefined>(undefined);

/**
 * Hook to access the AuthStateContext
 *
 * @return {*}  {IAuthState}
 */
export const useAuthStateContext = (): IAuthState => {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthStateContextProvider');
  }
  return context;
};
//================================================
// Config Context
//================================================

export interface IConfig {
  //Keycloak
  KEYCLOAK_URL: string;
  KEYCLOAK_REALM: string;
  KEYCLOAK_CLIENT_ID: string;
  WALLET_HOST: string;
}

export const ConfigContext = React.createContext<IConfig | undefined>(undefined);

/**
 * Hook to access the ConfigContext
 *
 * @return {*}  {IConfig}
 */
export const useConfigContext = (): IConfig => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

//================================================
// Tenant Context
//================================================

export interface ITenantContext {
  tenant: Tenant | undefined;
  invitations: IInvitation[] | undefined;
  users: IUser[] | undefined;
  schemas: any[] | undefined;
  credentials: any[] | undefined;
  fetchTenant: () => Promise<Tenant | undefined>;
  fetchUsers: () => Promise<IUser[] | undefined>;
  fetchInvitation: (reservationId: string) => Promise<IInvitation | undefined>;
  fetchInvitations: () => Promise<IInvitation[] | undefined>;
  fetchCredentials: (wallet_id: string) => Promise<any>;
  refreshCredentials: (wallet_id: string) => Promise<any>;
  fetchSchemas: () => Promise<any[] | undefined>;
  refresh: () => void;
  isLoading: boolean;
  isRefreshing: boolean;
  isLoadingCredentials: boolean;
  isRefreshingCredentials: boolean;
}

export const TenantContext = createContext<ITenantContext>({
  tenant: undefined,
  invitations: [],
  users: [],
  schemas: [],
  credentials: [],
  fetchTenant: async () => undefined,
  fetchUsers: async () => undefined,
  fetchInvitation: async () => undefined,
  fetchInvitations: async () => undefined,
  fetchCredentials: async () => undefined,
  refreshCredentials: async () => {},
  fetchSchemas: async () => undefined,
  refresh: () => {},
  isLoading: false,
  isRefreshing: false,
  isLoadingCredentials: false,
  isRefreshingCredentials: false
});

export type TenantContextProviderProps = {
  children: ReactNode;
};

/**
 * Hook to access the TenantContext
 *
 * @return {*}  {ITenant}
 */
export const useTenantContext = (): ITenantContext => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};

//================================================
// Toast Context
//================================================

export interface ToastContextType {
  showToast: (message: ReactNode, timeout: number, success?: boolean) => void;
}

export const ToastContext = React.createContext<ToastContextType | undefined>(undefined);

/**
 * Hook to access the ToastContext
 *
 * @return {*}  {ToastContextType}
 */
export const useToastContext = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

//================================================
// WebSocket Context
//================================================

export interface WebSocketContextType {
  sendMessage: (message: string) => void;
  lastMessage: MessageEvent | null;
  connectionStatus: string;
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

/**
 * Hook to access the WebSocketContext
 *
 * @return {*}  {WebSocketContextType}
 */
export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }
  return context;
};

//================================================
// Credential Context
//================================================

export interface CredentialContextType {
  schema: any;
  attributes: string[];
  name: string;
  setSchema: (schema: any) => void;
  sendCredential: (data: IUser) => Promise<void>;
  loading: boolean;
}

export const CredentialContext = React.createContext<CredentialContextType>({
  schema: null,
  attributes: [],
  name: '',
  setSchema: async () => {},
  sendCredential: async () => {},
  loading: false
});

export const useCredentialContext = (): CredentialContextType => {
  const context = useContext(CredentialContext);
  if (!context) {
    throw new Error('useCredentialContext must be used within a CredentialContextProvider');
  }
  return context;
};
