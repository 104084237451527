import React from 'react';
import BlankModal from './BlankModal';
import { useState } from 'react';

export interface DeleteConfirmModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

/**
 * DeleteConfirmModal component
 *
 * @param {*} {
 *   isVisible,
 *   onClose,
 *   onConfirm
 * }
 * @return {*}
 */
const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isVisible,
  onClose,
  onConfirm
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    onClose();
  };
  return (
    <BlankModal
      title="Are you sure you want to delete?"
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={() => handleConfirm()}
      acceptText="Confirm"
      isLoading={isLoading}>
      <></>
    </BlankModal>
  );
};

export default DeleteConfirmModal;
