import React from 'react';
import { Formik } from 'formik';
import { useTenantContext, useToastContext } from '../contexts';
import BlankModal from './BlankModal';
import RegisterUserForm from '../components/forms/RegisterUserForm';
import { useIssuerService } from '../services';

interface INewUser {
  email: string;
  name: string;
}

const newUserInitialValues: INewUser = {
  email: '',
  name: ''
};

//TODO: Add validation YUP Schema for form

export interface IOnboardUserModalProps {
  isVisible: boolean;
  onClose: () => void;
}

/**
 * OnboardUserModal component
 *
 * @param {*} { isVisible, onClose }
 * @return {*}
 */
const OnboardUserModal: React.FC<IOnboardUserModalProps> = ({ isVisible, onClose }) => {
  const issuerService = useIssuerService();
  const tenantContext = useTenantContext();
  const toast = useToastContext();

  const createInvitation = async (data: INewUser) => {
    await issuerService.registerNewUser(data);
    await tenantContext.refresh();
    toast.showToast('User invited successfully', 3000, true);
    onClose();
  };

  const handleClose = (resetForm: () => void) => {
    onClose();
    resetForm();
  };

  return (
    <Formik
      initialValues={newUserInitialValues}
      onSubmit={(values, { resetForm }) => {
        createInvitation(values);
        handleClose(resetForm);
      }}>
      {(props) => (
        <BlankModal
          title="Invite User"
          isVisible={isVisible}
          onClose={() => handleClose(props.resetForm)}
          onConfirm={props.handleSubmit}>
          <RegisterUserForm formikProps={props} />
        </BlankModal>
      )}
    </Formik>
  );
};

export default OnboardUserModal;
