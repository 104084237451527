import './App.css';
import AppRouter from './routes/AppRouter';
import Layout from './components/layout/Layout';
import React from 'react';
import { WebSocketProvider } from './contexts/WebSocketContext';

function App(): React.ReactElement {
  return (
    <WebSocketProvider walletId={'issuer'}>
      <Layout title="Issuer Application">
        <AppRouter />
      </Layout>
    </WebSocketProvider>
  );
}

export default App;
