import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import UsersTable from '../features/table/UsersTable';
import OnboardUserButton from '../components/molecules/buttons/OnboardUserButton';

/**
 * TenantPage component
 *
 * @return {*}
 */
const TenantPage: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.inviteContainer}>
        <Text style={styles.headerText}>Wallets</Text>
        <OnboardUserButton title="+ Invite User" />
      </View>
      <View style={styles.usersContainer}>
        <UsersTable />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 8,
    paddingTop: 0
  },
  inviteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 30,
    margin: 0
  },
  usersContainer: {
    flex: 1,
    width: '100%'
  },
  headerText: {
    fontSize: 32,
    fontWeight: 700
  }
});

export default TenantPage;
