// User Invitation Interface
export interface IUserInvitation {
  reservation_id: string;
  reservation_pwd: string;
  email: string;
  tenant_name: string;
}

export interface IInvitation {
  user_id?: string;
  reservation_id: string;
  reservation_pwd: string;
  tenant_name: string;
  email: string;
}

export interface IGetUserInvitation {
  reservation_id?: string;
  reservation_pwd?: string;
  email: string;
  tenant_name: string;
}

export class UserInvitation implements IUserInvitation {
  reservation_id: string;
  reservation_pwd: string;
  email: string;
  tenant_name: string;

  constructor(data: IInvitation) {
    this.reservation_id = data.reservation_id;
    this.reservation_pwd = data.reservation_pwd;
    this.email = data.email;
    this.tenant_name = data.tenant_name;
  }
}
