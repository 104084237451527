import React from 'react';
import { StyleSheet, View } from 'react-native';
import Skeleton from './Skeleton';

/**
 * CredentialSkeleton component
 * Displays a loading skeleton for credential items
 *
 * @return {React.FC}
 */
const CredentialSkeleton: React.FC = () => {
  return (
    <View style={styles.credentialContainer}>
      <View style={styles.credentialHeader}>
        <View>
          {/* Name skeleton */}
          <Skeleton skeletonStyle={[styles.primarySkeleton, { marginLeft: 24 }]} />
          {/* "Credential Details" skeleton */}
          <Skeleton skeletonStyle={[styles.secondarySkeleton, { marginLeft: 24, marginTop: 4 }]} />
        </View>
        {/* Revoke button skeleton */}
        <Skeleton skeletonStyle={styles.revokeButtonSkeleton} />
      </View>

      {/* Attribute skeletons - showing 3 placeholder attributes */}
      {[1, 2, 3].map((_, index) => (
        <View style={{ flexDirection: 'row' }} key={index}>
          {/* Attribute name skeleton */}
          <Skeleton
            skeletonStyle={[
              styles.attributeSkeleton,
              { marginLeft: 48, width: '30%', marginRight: 8 }
            ]}
          />
          {/* Attribute value skeleton */}
          <Skeleton skeletonStyle={[styles.attributeSkeleton, { width: '50%' }]} />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  credentialContainer: {
    flexDirection: 'column',
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 24
  },
  primarySkeleton: {
    height: 20,
    width: 200,
    borderRadius: 4
  },
  secondarySkeleton: {
    height: 16,
    width: 150,
    borderRadius: 4
  },
  attributeSkeleton: {
    height: 16,
    marginTop: 8,
    borderRadius: 4
  },
  revokeButtonSkeleton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  }
});

export default CredentialSkeleton;
