import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Tenant from '../types/tenant';
import { TenantContext, TenantContextProviderProps, useWebSocketContext } from '.';
import { IUser } from '../types/user';
import { IInvitation } from '../types/invitation';
import { useIssuerService } from '../services';

/**
 * TenantContextProvider
 *
 * @param {*} { children }
 * @return {*}
 */
const TenantContextProvider: React.FC<TenantContextProviderProps> = ({ children }) => {
  const issuerService = useIssuerService();
  const { lastMessage } = useWebSocketContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [isRefreshingCredentials, setIsRefreshingCredentials] = useState(false);

  /*
   * State Variables
   */
  const [tenantData, setTenantData] = useState<Tenant | undefined>(undefined);
  const [userData, setUserData] = useState<IUser[] | undefined>(undefined);
  const [invitationData, setInvitationData] = useState<IInvitation[] | undefined>(undefined);
  const [schemaData, setSchemaData] = useState<any[] | undefined>(undefined);
  const [credentialsData, setCredentialsData] = useState<any[] | undefined>(undefined);

  /*
   * Memoize the values
   */
  const tenant = useMemo(() => tenantData, [tenantData]);
  const users = useMemo(() => userData, [userData]);
  const invitations = useMemo(() => invitationData, [invitationData]);
  const schemas = useMemo(() => schemaData, [schemaData]);
  const credentials = useMemo(() => credentialsData, [credentialsData]);

  /*
   * Tenant Context Functions
   */
  const fetchTenant = useCallback(async () => {
    const response = await issuerService.fetchTenantData();
    if (response) setTenantData(response);
    return response;
  }, [issuerService]);

  /*
   * User Context Functions
   */
  const fetchUsers = useCallback(async () => {
    const response = await issuerService.fetchUserData();
    if (response) setUserData(response);
    return response;
  }, [issuerService]);

  /*
   * Invitation Context Functions
   */
  const fetchInvitation = useCallback(
    async (reservationId: string) => {
      const reservation = await issuerService.fetchInvitationData(reservationId);
      return reservation;
    },
    [issuerService]
  );

  const fetchInvitations = useCallback(async () => {
    const response = await issuerService.fetchInvitationsData();
    if (response) setInvitationData(response);
    return response;
  }, [issuerService]);

  /*
   * Credential Context Functions
   */
  const fetchCredentials = useCallback(
    async (connection_id: string) => {
      setIsLoadingCredentials(true);
      const credentials = await issuerService.fetchCredentialData(connection_id);
      if (credentials) setCredentialsData(credentials);
      setIsLoadingCredentials(false);
      return credentials;
    },
    [issuerService]
  );

  /*
   * Refresh Credentials Context Functions
   */
  const refreshCredentials = useCallback(
    async (connection_id: string) => {
      setIsRefreshingCredentials(true);
      const credentials = await issuerService.fetchCredentialData(connection_id);
      if (credentials) setCredentialsData(credentials);
      setIsRefreshingCredentials(false);
    },
    [issuerService]
  );

  /*
   * Schema Context Functions
   */
  const fetchSchemas = useCallback(async () => {
    const response = await issuerService.fetchSchemaData();
    if (response) setSchemaData(response);
    return response;
  }, [issuerService]);

  /*
   * Initialize Context Data
   */
  const initialize = useCallback(async () => {
    setIsLoading(true);
    await fetchTenant();
    await fetchSchemas();
    await fetchInvitations();
    await fetchUsers();
    setIsLoading(false);
  }, [fetchTenant, fetchSchemas, fetchInvitations, fetchUsers]);

  /*
   * Refresh Context Functions
   */
  const refresh = useCallback(async () => {
    setIsRefreshing(true);
    await fetchSchemas();
    await fetchInvitations();
    await fetchUsers();
    setIsRefreshing(false);
  }, [fetchUsers, fetchInvitations, fetchSchemas]);

  useEffect(() => {
    const fetchData = async () => {
      await initialize();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Refresh Context Functions
   */
  useEffect(() => {
    const fetchData = async () => {
      await fetchInvitations();
      await fetchUsers();
    };
    if (lastMessage?.data === 'refresh-users' || lastMessage?.data === 'refresh-credentials') {
      fetchData();
    }
  }, [lastMessage, fetchInvitations, fetchUsers]);

  return (
    <TenantContext.Provider
      value={{
        tenant,
        invitations,
        users,
        schemas,
        credentials,
        fetchTenant,
        fetchUsers,
        fetchInvitation,
        fetchInvitations,
        fetchCredentials,
        refreshCredentials,
        fetchSchemas,
        refresh,
        isLoading,
        isRefreshing,
        isLoadingCredentials,
        isRefreshingCredentials
      }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContextProvider;
