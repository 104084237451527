import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View, Easing, DimensionValue, Text } from 'react-native';

/**
 * Spinner component
 *
 * @return {*}
 */
const Spinner: React.FC = () => {
  const theme = {
    primary: '#1C274C',
    secondary: '#ADB9E1'
  };
  const rotationOuter = useRef(new Animated.Value(0)).current;
  const rotationInner = useRef(new Animated.Value(0)).current;
  const spinnerInner = (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42857 44.0001C5.42857 22.6977 22.6976 5.42871 44 5.42871"
        stroke={theme.primary}
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M82.5714 44.0004C82.5714 65.3028 65.3024 82.5718 44 82.5718"
        stroke={theme.secondary}
        strokeWidth="10"
        strokeLinecap="round"
      />
    </svg>
  );

  const spinnerOuter = (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65 125C31.8629 125 5 98.1371 5 65"
        stroke={theme.primary}
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M65 4.99999C98.1371 5 125 31.8629 125 65"
        stroke={theme.secondary}
        strokeWidth="10"
        strokeLinecap="round"
      />
    </svg>
  );
  useEffect(() => {
    const spinOuterAnimation = Animated.loop(
      Animated.timing(rotationOuter, {
        toValue: 1,
        duration: 1500,
        useNativeDriver: false,
        easing: Easing.linear
      })
    );

    const spinInnerAnimation = Animated.loop(
      Animated.timing(rotationInner, {
        toValue: 1,
        duration: 1500,
        useNativeDriver: false,
        easing: Easing.linear
      })
    );

    spinOuterAnimation.start();
    spinInnerAnimation.start();

    return () => {
      spinOuterAnimation.stop();
      spinInnerAnimation.stop();
    };
  }, [rotationOuter, rotationInner]);

  const spinOuter = rotationOuter.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-360deg']
  });

  const spinInner = rotationInner.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  });

  return (
    <View style={styles.spinnerContainer}>
      <Animated.View style={{ transform: [{ rotate: spinOuter }] }}>{spinnerOuter}</Animated.View>
      <Animated.View
        style={{
          position: 'absolute',
          transform: [{ rotate: spinInner }]
        }}>
        {spinnerInner}
      </Animated.View>
    </View>
  );
};

interface SpinnerProps {
  text?: string;
}

export const FullScreenSpinner: React.FC<SpinnerProps> = ({ text }) => {
  return (
    <View style={styles.container}>
      <Spinner />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

export default Spinner;

const styles = StyleSheet.create({
  spinnerContainer: {
    width: 62,
    height: 62,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  logo: {
    width: 48,
    height: 48
  },
  container: {
    flex: 1,
    width: '100vw' as DimensionValue,
    height: '100vh' as DimensionValue,
    justifyContent: 'center',
    alignItems: 'center'
  },
  customSize: {
    transform: [{ scale: 1.5 }]
  },
  loader: {
    width: 48,
    height: 48,
    borderRadius: 24,
    borderTopWidth: 3,
    borderRightWidth: 3,
    borderColor: '#BA0020',
    borderRightColor: 'transparent'
  },
  text: {
    fontSize: 18,
    fontWeight: 700,
    margin: 50
  }
});
