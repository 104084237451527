import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import GenericButton from '../../../../components/molecules/buttons/GenericButton';

interface ICredentialItemProps {
  credential: any;
}

/**
 * CredentialItem component
 *
 * @param {*} { user, credentials }
 * @return {*}
 */
const CredentialItem: React.FC<ICredentialItemProps> = ({ credential }) => {
  return (
    <View style={styles.credentialContainer} key={`${credential.credential_id}`}>
      <View style={styles.credentialHeader}>
        <View>
          <Text style={[styles.primaryText, { marginLeft: 24 }]}>{credential.name}</Text>
          <Text style={[styles.secondaryText, { marginLeft: 24 }]}>Credential Details:</Text>
        </View>
        <GenericButton
          title={'Revoke'}
          onPress={function (): void {
            throw new Error('Function not implemented.');
          }}
          buttonStyle={styles.revokeButton}
          textStyle={styles.buttonText}
          isPrimary={true}
        />
      </View>
      {credential.attributes.map((attribute: any, index: number) => {
        return (
          <View style={{ flexDirection: 'row' }} key={`${credential.credential_id}-${index}`}>
            <Text style={[styles.secondaryText, { marginLeft: 48 }]}>{`${attribute.name}: `}</Text>
            <Text style={styles.secondaryText}>{attribute.value}</Text>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  credentialContainer: {
    flexDirection: 'column',
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 24
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  }
});

export default CredentialItem;
