import React from 'react';
import { FlatList } from 'react-native';
import { IUser } from '../../../../types/user';
import CredentialItem from './CredentialItem';
import { useTenantContext } from '../../../../contexts';
import EmptyCredential from './EmptyCredential';
import CredentialSkeleton from '../../../../components/skeletons/CredentialSkeleton';

interface ICredentialListProps {
  user: IUser;
  credentials: any[];
  setSendUserCredModalOpen: (value: boolean) => void;
}

/**
 * CredentialList component
 *
 * @param {*} { user, credentials }
 * @return {*}
 */
const CredentialList: React.FC<ICredentialListProps> = ({
  user,
  credentials,
  setSendUserCredModalOpen
}) => {
  const tenantContext = useTenantContext();

  const renderItem = ({ item, index }: { item: any; index: number }) => (
    <CredentialItem credential={item} key={index} />
  );

  const ListFooterComponent = () =>
    tenantContext.isRefreshingCredentials ? <CredentialSkeleton /> : null;

  return (
    <>
      {tenantContext.isLoadingCredentials ? (
        <>
          <CredentialSkeleton />
          <CredentialSkeleton />
          <CredentialSkeleton />
        </>
      ) : (
        <>
          {credentials.length === 0 ? (
            <>
              {tenantContext.isRefreshingCredentials ? (
                <CredentialSkeleton />
              ) : (
                <EmptyCredential user={user} setSendUserCredModalOpen={setSendUserCredModalOpen} />
              )}
            </>
          ) : (
            <FlatList
              data={credentials}
              renderItem={renderItem}
              keyExtractor={(item) => item.credentialExchangeId as string}
              ListFooterComponent={ListFooterComponent}
              ListFooterComponentStyle={{ marginBottom: 20 }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CredentialList;
