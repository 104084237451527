import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import GenericButton from '../../../../components/molecules/buttons/GenericButton';
import EmptyCredentialIcon from '../../../../components/icons/EmptyCredentialIcon';
import { IUser } from '../../../../types/user';

interface IEmptyCredentialProps {
  user: IUser;
  setSendUserCredModalOpen: (value: boolean) => void;
}

/**
 * EmptyCredential component
 *
 * @param {*} { user, setSendUserCredModalOpen }
 * @return {*}
 */
const EmptyCredential: React.FC<IEmptyCredentialProps> = ({ user, setSendUserCredModalOpen }) => {
  return (
    <View style={styles.emptyCredentialContainer}>
      <EmptyCredentialIcon />
      <Text style={styles.noCredentialHeaderText}>No credentials</Text>
      <Text
        style={styles.secondaryText}>{`${user?.tenant_name} doesn't have any credentials`}</Text>
      <GenericButton
        title={'Issue Credential'}
        onPress={() => setSendUserCredModalOpen(true)}
        buttonStyle={styles.button}
        textStyle={styles.buttonText}
        isPrimary={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  emptyCredentialContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialContainer: {
    flexDirection: 'column',
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 24
  },
  button: {
    width: 141,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  noCredentialHeaderText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  }
});

export default EmptyCredential;
