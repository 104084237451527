import React from 'react';
import WalletUserItem from './wallet-components/WalletUserItem';
import InvitationUserItem from './invitation-components/InvitationUserItem';
import { IUser } from '../../types/user';
import { IInvitation } from '../../types/invitation';

interface IUserItemProps {
  user: IUser & IInvitation;
}

/**
 * UserItem component
 *
 * @param {*} { user }
 * @return {*}
 */
const UserItem: React.FC<IUserItemProps> = ({ user }) => {
  return (
    <>{user.reservation_id ? <InvitationUserItem user={user} /> : <WalletUserItem user={user} />}</>
  );
};

export default UserItem;
