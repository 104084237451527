import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Layout from '../components/layout/Layout';
import { useAdminService } from '../services';
/**
 * AdminPage component protected by AdminAuthGuard
 * Displays basic admin interface
 *
 * @return {JSX.Element}
 */
const AdminPage: React.FC = () => {
  const adminService = useAdminService();

  useEffect(() => {
    adminService.fetchTest().then((test) => {
      console.log(test);
    });
  }, [adminService]);

  return (
    <Layout title="Admin Dashboard">
      <View style={styles.container}>
        <Text style={styles.header}>Admin Dashboard</Text>
        <View style={styles.contentSection}>
          <Text style={styles.sectionTitle}>Welcome to the Admin Panel</Text>
          <Text style={styles.description}>
            This area is restricted to administrators only. Use this dashboard to manage system
            settings and user permissions.
          </Text>
        </View>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  header: {
    fontSize: 32,
    fontWeight: '700',
    color: '#000000',
    marginBottom: 24
  },
  contentSection: {
    backgroundColor: '#ffffff',
    padding: 24,
    borderRadius: 8,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#30394C',
    marginBottom: 16
  },
  description: {
    fontSize: 16,
    color: '#6B7280',
    lineHeight: 24
  }
});

export default AdminPage;
